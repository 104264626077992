<template>
  <main class="partner">
    <div class="partner_wrap">
      <h1>
        성공적인 비즈니스 파트너,<br />
        Blue Button과 함께라면 여러분도 앞서갈 수 있습니다.
      </h1>
      <div class="partner_form">
        <form @submit.prevent="formSubmit" method="post">
          <div class="table_wrap">
            <table>
              <tr class="partner_division">
                <th>구분</th>
                <td width="140px">
                  <input
                    type="checkbox"
                    name="division"
                    id="allianceYn"
                    v-model="allianceYn"
                  />
                  <label for="allianceYn">제휴문의</label>
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="division"
                    id="adverYn"
                    v-model="adverYn"
                  />
                  <label for="adverYn">광고문의</label>
                </td>
              </tr>
              <tr>
                <th>회사명(성명)</th>
                <td colspan="2">
                  <ValidationProvider
                    name="회사명(성명)"
                    rules="required"
                    ref="refCompany"
                  >
                    <input
                      type="text"
                      placeholder="회사명(성명)을 입력해주세요."
                      class="width_100"
                      required
                      id="company_name"
                      v-model="company_name"
                    />
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td colspan="2">
                  <input
                    type="number"
                    placeholder=" “-”를 제외한 휴대폰 번호를 입력해 주세요"
                    class="width_100"
                    id="mobile"
                    v-model="mobile"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>이메일 주소</th>
                <td colspan="2" class="partner_email">
                  <input type="text" v-model="email_head" ref="refEmailInput" />
                  @
                  <input
                    v-if="this.selected != 'direct'"
                    type="text"
                    :value="selected"
                    disabled
                  />
                  <input
                    v-if="this.selected == 'direct'"
                    type="text"
                    id="drect_email"
                    v-model="direct_email"
                  />
                  <div class="select_box select_w_138">
                    <select
                      name="partner_email"
                      id="partner_email"
                      v-model="selected"
                      @change="getEmail"
                    >
                      <option value="" disabled selected>메일 선택</option>
                      <option value="gmail.com">gmail.com</option>
                      <option value="naver.com">naver.com</option>
                      <option value="daum.net">daum.net</option>
                      <option value="kakao.com">kakao.com</option>
                      <option value="hotmail.com">hotmail.com</option>
                      <option value="direct">직접입력</option>
                    </select>
                  </div>
                  <ValidationProvider
                    name="이메일"
                    rules="required|email"
                    ref="refEmail"
                  >
                    <input class="hidden" type="email" v-model="email" />
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="2" class="partner_zipcode">
                  <p>
                    <input type="text" id="zipcode" :value="zipcode" />
                    <button type="button" @click="showApi">우편번호</button>
                  </p>
                  <input
                    type="text"
                    class="width_100"
                    id="adress"
                    :value="addr"
                  />
                </td>
              </tr>
              <tr class="partner_desc">
                <th>문의내용</th>
                <td colspan="2">
                  <ValidationProvider
                    name="문의내용"
                    rules="required"
                    ref="refQuestion"
                  >
                    <textarea
                      name="partner_desc"
                      id="partner_question"
                      placeholder="문의 내용을 작성해 주세요."
                      v-model="partner_content"
                    ></textarea>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <th>첨부파일</th>
                <td colspan="2" class="partner_file">
                  <input type="text" v-model="fileName" />
                  <label
                    for="selectFile"
                    class="partner_input_file input_file_button"
                    >파일 찾기</label
                  >
                  <input
                    type="file"
                    id="selectFile"
                    accept=".zip, .gz, .tgz .xls*, .ppt*, .doc*, .hwp, .txt, .png, .jpg, .jpeg .pdf, .bmp, .gif"
                    ref="selectFile"
                    @change="onChageFile"
                  />
                </td>
              </tr>
            </table>
          </div>
        </form>
        <div class="partner_submit_btn">
          <button type="submit" @click="formSubmit">문의하기</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  data() {
    return {
      zipcode: "",
      addr: "",
      allianceYn: true,
      adverYn: "",
      company_name: "",
      mobile: "",
      email_head: "",
      selected: "",
      direct_email: "",
      email: "",
      partner_content: "",
      selectFile: "",
      fileName: "",
      url: "/api/board/contact",
      file_url: "/api/attach",
    };
  },
  created() {
    console.log(client);
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async formSubmit() {
      let validation = await this.validation();
      if (validation) {
        this.create();
      }
    },

    async validation() {
      const refCompany = await this.$refs.refCompany.validate(),
        refEmail = await this.$refs.refEmail.validate(),
        refQuestion = await this.$refs.refQuestion.validate();

      if (this.allianceYn == "" && this.adverYn == "") {
        alert("구분을 입력해 주세요.");
        return false;
      }

      if (!refCompany.valid) {
        alert(refCompany.errors[0]);
        document.getElementById("company_name").focus();
        return false;
      }

      if (!/^01(?:0|1|[6-9])([0-9]{3,4})([0-9]{4})$/.test(this.mobile)) {
        alert("휴대폰번호를 정확히 입력해 주세요.");
        document.getElementById("mobile").focus();
        return false;
      }

      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }

      if (!refQuestion.valid) {
        alert(refQuestion.errors[0]);
        return false;
      }

      return true;
    },
    async create() {
      if (this.selectFile) {
        let form = new FormData();
        form.append("attach", this.selectFile);

        await client.attachUpload(form).then(
          (response) => {
            this.attachCd = response.data[0].attachCd;
          },
          (error) => {
            console.log(error);
          }
        );
      }

      this.adverYn == true ? (this.adverYn = "Y") : (this.adverYn = "");
      this.allianceYn == true
        ? (this.allianceYn = "Y")
        : (this.allianceYn = "");

      const params = {
        adverYn: this.adverYn,
        allianceYn: this.allianceYn,
        company: this.company_name,
        mobile: this.mobile,
        email: this.email,
        zipcode: this.zipcode,
        address: this.addr,
        content: this.partner_content,
        attachCd: this.attachCd,
      };

      console.log(client);
      await client.boardContactReg(params).then(
        () => {
          alert(
            "제휴.광고문의 접수가 완료되었습니다. 담당자 확인 후 빠른 시간 내에 연락 드리겠습니다."
          );
          this.$router.push({
            name: "Main",
          });
        },
        (error) => {
          alert(error);
        }
      );
    },
    getEmail() {
      if (this.selected != "direct") {
        this.email = [this.email_head, this.selected];
        this.email = this.email.join("@");
      }
      if (this.selected == "direct") {
        this.email = [this.email_head, this.direct_email];
        this.email = this.email.join("@");
      }
    },
    showApi() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let addr = data.roadAdress;
          let extraAddr = "";
          if (data.userSelectedType === "R") {
            addr = data.roadAddress;
          } else {
            addr = data.jibunAddress;
          }
          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            this.addr = extraAddr;
          } else {
            this.addr = "";
          }
          this.zipcode = data.zonecode;
          this.addr = addr + extraAddr;
          document.getElementById("adress").focus();
        },
      }).open();
    },
    getFileName() {
      document.getElementById("fileName").value;
    },

    onChageFile(e) {
      if (0 < this.$refs.selectFile.files.length) {
        this.selectFile = this.$refs.selectFile.files[0];
        let fileExt = this.selectFile.name.substring(
          this.selectFile.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          [
            "zip",
            "gz",
            "tgz",
            "hwp",
            "txt",
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const file = e.target.files[0];
          this.fileName = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile01 = null;
        }
      }
    },
  },
};
</script>
